import { defineComponent, computed } from '@vue/composition-api';
import { StoreKey } from '@/symbols';
import { injectStrict } from '@/lib/misc';
import AuthMenu from '@/components/AuthMenu.vue';
export default defineComponent({
    components: {
        AuthMenu
    },
    setup() {
        const store = injectStrict(StoreKey);
        const items = computed(() => [
            {
                text: 'Inregistrare',
                props: {
                    to: { name: 'register' },
                    color: 'green'
                },
                on: {},
                icon: 'mdi-account-plus',
                visible: store.getters['auth/isGuest']
            },
            {
                text: 'Autentificare',
                props: {
                    to: { name: 'login' },
                    color: 'primary'
                },
                on: {},
                icon: 'mdi-account-key',
                visible: store.getters['auth/isGuest']
            },
            {
                text: 'Sesizari',
                props: {
                    to: { name: 'user.citizen-reports' },
                    color: 'indigo'
                },
                on: {},
                icon: 'mdi-map-marker-check',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Raporteaza',
                props: {
                    to: { name: 'report' },
                    color: 'success'
                },
                on: {},
                icon: 'mdi-map-marker-plus',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Harta',
                props: {
                    to: { name: 'citizen-reports' },
                    color: 'warning'
                },
                on: {},
                icon: 'mdi-map-marker-multiple',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Iesire',
                props: {
                    color: 'red'
                },
                on: {
                    click: () => store.dispatch('auth/logout')
                },
                icon: 'mdi-exit-run',
                visible: !store.getters['auth/isGuest']
            }
        ]);
        const rows = computed(() => items.value.filter(itm => itm.visible).length / 2);
        const height = computed(() => {
            const vh = rows.value === 1 ? 50 : (100 / rows.value);
            return `calc(${vh}vh - 70px) !important`;
        });
        return {
            rows,
            items,
            height
        };
    }
});
